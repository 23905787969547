import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Button from "./button";
import { IoMdHeart } from "react-icons/io";
import { TiThMenu } from "react-icons/ti";
import { IoCloseSharp } from "react-icons/io5";
const Navbar = () => {
  const [activeLink, setActiveLink] = useState(null);
  const [opentoogle, setopentoogle] = useState(false);

  const handleNavLinkClick = (index) => {
    setActiveLink(index);
    setopentoogle(false);
  };

  const handleOpen = () => {
    setopentoogle(true);
  };
  const handleClose = () => {
    setopentoogle(false);
  };
  const [active, setActive] = useState(
    localStorage.getItem("activeNavItem") || "home"
  );
  const navLinks = [
    { label: "Home", to: "/" },
    { label: "About us ", to: "/about" },
    { label: "Services", to: "/services" },
    {
      label: "Destinations",
      sublinks: [
        { to: "/Rwanda", label: "Rwanda" },
        { to: "/Uganda", label: "Uganda" },
      ],
    },
    {
      label: "Itineraries",
      sublinks: [
        { to: "/Rwanda-Itineraries", label: "Rwanda " },
        { to: "/Uganda-Itineraries", label: "Uganda " },
        { to: "/Packages", label: "Packages " },
      ],
    },
    { label: "Contact us", to: "/contact" },
  ];
  const goTODoc = () => {
    window.location.assign(
      "https://docs.google.com/forms/d/e/1FAIpQLSf2WGtPQWyZGgFisJ-Wl2lnYghkCaklYo-kmvXYGsO11t5f-A/viewform?embedded=true"
    );
  };
  return (
    <nav className="navbar-container">
      <div className="logo-container">
        <img src="/images/trumps.png" alt="Logo" />{" "}
      </div>
      <p className="tours">TRUMPET TOURS</p>

      {opentoogle && opentoogle == true ? (
        <button
          className="onltoogle "
          type="button"
          onClick={() => handleClose()}
        >
          <IoCloseSharp className="iconyu" />
        </button>
      ) : (
        <button
          className="onltoogle "
          type="button"
          onClick={() => handleOpen()}
        >
          <TiThMenu className="iconyu" />
        </button>
      )}

      <div className="nav-links-container">
        <ul>
          {navLinks.map((link, index) => (
            <li
              key={link.to}
              className={`relative group subtile${
                active === link.label.toLowerCase() ? "group-hover:flex" : ""
              }`}
            >
              <Link
                key={index}
                to={link.to}
                className={`nav-link ${activeLink === index ? "active" : ""}`}
                onClick={() => handleNavLinkClick(index)}
              >
                {link.label}
              </Link>
              {link.sublinks && (
                <div className="dropdown-content">
                  {link.sublinks.map((sublink) => (
                    <li
                      key={sublink.to}
                      className="drop"
                      onClick={() => handleNavLinkClick(index)}
                    >
                      <Link to={sublink.to} className="droplink">
                        {sublink.label}
                      </Link>
                    </li>
                  ))}
                </div>
              )}
            </li>
          ))}
        </ul>

        <Button
          path="/Plan"
          // click={() => goTODoc()}
          title="Plan Your Trip"
          // icon={<IoMdHeart className="scale-105" />}
        />

        <div className="schoolModal">
          <Modal show={opentoogle} className="modalAll">
            <Modal.Body className="Madl">
              <div>
                <p className="closeModale">
                  <i className="fas fa-times"></i>
                </p>
                <ul>
                  {navLinks.map((link, index) => (
                    <li
                      key={link.to}
                      className={`relative group subtile ${
                        active === link.label.toLowerCase()
                          ? "group-hover:flex"
                          : ""
                      }`}
                    >
                      <Link
                        key={index}
                        to={link.to}
                        className={`nav-link ${
                          activeLink === index ? "active" : ""
                        }`}
                        onClick={() => handleNavLinkClick(index)}
                      >
                        {link.label}
                      </Link>
                      {link.sublinks && (
                        <div className="dropdown-content">
                          {link.sublinks.map((sublink) => (
                            <li
                              key={sublink.to}
                              className="drop"
                              onClick={() => handleNavLinkClick(index)}
                            >
                              <Link to={sublink.to} className="droplink">
                                {sublink.label}
                              </Link>
                            </li>
                          ))}
                        </div>
                      )}
                    </li>
                  ))}
                </ul>
                <div className="btngo">
                  <Button
                    path="/Plan"
                    click={() => handleClose()}
                    title="Plan Your Trip"
                    // icon={<IoMdHeart className="scale-105" />}
                  />
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
