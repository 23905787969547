import React, { useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/home";
import About from "./pages/about";
import Layout from "./main/Layout";
import Services from "./pages/services";
import ContactForm from "./pages/contact";
import Rwanda from "./pages/destination";
import Uganda from "./pages/uganda";
import UgaItineraries from "./pages/ugandaiten";
import RwaItineraries from "./pages/rwandaItern";
import Itineraries from "./pages/iternature";
import Plan from "./pages/plan";
import Packages from "./pages/packages";
function App() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Router>
      <Routes>
        <Route
          path="/about"
          component={About}
          element={
            <Layout>
              <About />
            </Layout>
          }
        />
        {/* <Route path="/contact" component={Contact} /> */}
        <Route
          component={Home}
          path="/"
          element={
            <Layout>
              <Home />
            </Layout>
          }
        />
        <Route
          component={Services}
          path="/services"
          element={
            <Layout>
              <Services />
            </Layout>
          }
        />
        <Route
          component={ContactForm}
          path="/contact"
          element={
            <Layout>
              <ContactForm />
            </Layout>
          }
        />
        <Route
          component={Rwanda}
          path="/Rwanda"
          element={
            <Layout>
              <Rwanda />
            </Layout>
          }
        />
        <Route
          component={Uganda}
          path="/Uganda"
          element={
            <Layout>
              <Uganda />
            </Layout>
          }
        />
        <Route
          component={UgaItineraries}
          path="/Uganda-Itineraries"
          element={
            <Layout>
              <UgaItineraries />
            </Layout>
          }
        />
        <Route
          component={RwaItineraries}
          path="/Rwanda-Itineraries"
          element={
            <Layout>
              <RwaItineraries />
            </Layout>
          }
        />
        <Route
          component={Itineraries}
          path="/Itineraries/:id"
          element={
            <Layout>
              <Itineraries />
            </Layout>
          }
        />
        <Route
          component={Plan}
          path="/Plan"
          element={
            <Layout>
              <Plan />
            </Layout>
          }
        />
        <Route
          component={Packages}
          path="/Packages"
          element={
            <Layout>
              <Packages />
            </Layout>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
